const Swal = require('sweetalert2');

$(function () {
    $(document).on('click', '.btn-detail-special-stage, .btn-detail-special-stage-pickup', function (e) {
        
        if ($(this).hasClass('is-modal')) {
            e.preventDefault();
            const url = $(this).attr('href');
            Swal.fire({
                template: '#modal-special-stage',
                didRender(popup) {
                    if (url) {
                        $('#inlineFrame').attr('src', url)
                    } else{
                        $('#inlineFrame').css({'visibility': 'hidden;opacity:0;'})
                    }
                },
                willClose(popup) {}
            });
            return false;
        } else {
            return true;
        }
    })
    $.fn.newsticker = function(opts) {
        // default configuration
        var config = $.extend({}, {
            height: 65,
            speed: 500,
            start: 0,
            interval: 5000,
            move: null
        }, opts);
        // main function
        function init(obj) {
            var $newsticker = obj,
                $frame = $newsticker.find('.newsticker-list'),
                $item = $frame.find('.newsticker-item'),
                $next,
                stop = false;

            function init(){
                $item.eq(0).addClass('current'); //set start item
                suspend();
                move();
            };

            function suspend(){
                $newsticker.on('mouseover mouseout', function(e) {
                    if (e.type == 'mouseover') {
                        stop = true;
                    } else { //mouseout
                        stop = false;
                    }
                });
            };

            function move(){
                if($.isFunction(config.move)){
                    config.move.call(this);
                }
                else{
                    setInterval(function() {
                        if (!stop) {
                            var $current = $frame.find('.current');

                            $frame.animate({
                                top: '-=' + config.height + 'px'
                            }, config.speed, function() {
                                $next = $frame.find('.current').next();
                                $next.addClass('current');
                                $current.removeClass('current');
                                $current.clone().appendTo($frame);
                                $current.remove();
                                $frame.css('top', config.start + 'px');
                            });
                        }
                    }, config.interval);
                }
            };

            init();
        }
        // initialize every element
        this.each(function() {
            init($(this));
        });
        return this;
    };
    // start
    $(function() {
        $('.newsticker').newsticker();
    });
});


$(function () {
    $(window).scroll(function () {
        $('.fade, .fadeLeft, .fadeRight').each(function () {
            var top = $(this).offset().top + window.innerHeight / 4;
            var scroll = $(window).scrollTop() + window.innerHeight;
            if (scroll > top) {
                $(this).addClass('active');
            }
        })
    }).trigger('scroll');
});